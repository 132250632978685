<template>
    <div></div>
</template>

<script>
	import * as OrderApi from '@/api/order'
    import PayTypeEnum from '@/common/enum/order/PayType'
	export default {
		data() {
			return{
                PayTypeEnum,
				payment: '',
				// 当前订单ID
				orderId: null,
				// 当前订单详情
				order: {},
				ordertype: 'order',
			}
		},
		created() {
			if(this.$route.query.id){
				this.orderId = this.$route.query.id
				this.payOrder()
			}
		},
		methods:{
			// 获取当前订单信息
            payOrder() {
                const app = this
                OrderApi.pay(app.orderId, PayTypeEnum.WECHAT.value)
                    .then(result => {
                        let payment = result.data.payment
                        WeixinJSBridge.invoke(
                            'getBrandWCPayRequest', {
                                "appId": payment.appid,     //公众号ID，由商户传入
                                "timeStamp": payment.timeStamp,         //时间戳，自1970年以来的秒数
                                "nonceStr": payment.nonceStr, //随机串
                                "package":"prepay_id=" + payment.prepay_id,
                                "signType":"MD5",         //微信签名方式：
                                "paySign": payment.paySign //微信签名
                            },
                            function(res){
                                if(res.err_msg == "get_brand_wcpay_request:ok" ){
                                    // 使用以上方式判断前端返回,微信团队郑重提示：
                                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                                }
                            });
                    })
			},
		}
	}
</script>

<style lang="scss" scoped>
</style>
